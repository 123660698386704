import * as React from 'react';

import styled from 'styled-components';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { deviceBreakpoints } from 'utils/hooks/useDeviceQuery.hook';

import { useBookData } from './useBookData';

const Main = styled.section`
  background-color: ${colorsV2.green100};
  display: grid;
  place-items: center;
  text-align: center;
  padding: 32px;
  .content-wrapper {
  }

  @media ${deviceBreakpoints.lg} {
    padding: 80px;
  }
`;

const BookSellingHeroBanner = () => {
  const { bannerTitle } = useBookData();

  return (
    <Main className="book-selling-hero-banner">
      <div className="content-wrapper">
        <Typography
          style={{ color: colorsV2.white100 }}
          variant="semi-bold/16"
          desktopVariant="semi-bold/24"
        >
          {bannerTitle}
        </Typography>
      </div>
    </Main>
  );
};

export default BookSellingHeroBanner;
