import * as React from 'react';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import { withMarketingSection } from 'components/withMarketingSection';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import BookProductContent from 'shared/LandingBookSelling/BookProductContent';
import BookProductDescription from 'shared/LandingBookSelling/BookProductDescription';
import BookProductFooter from 'shared/LandingBookSelling/BookProductFooter';
import BookSellingHeroBanner from 'shared/LandingBookSelling/BookSellingHeroBanner';
import MySEO from 'shared/MySEO';

/* eslint-disable react/prop-types */
const Main = styled.div`
  .page-content {
    margin-bottom: 24px;
  }
`;

const ContentSection = withMarketingSection(
  () => {
    return (
      <div className="page-content">
        <BookProductContent />
        <BookProductDescription />
      </div>
    );
  },
  {
    style: {
      overflow: 'visible',
      paddingTop: 0,
      paddingBottom: 0
    },
    selfish: true
  }
);
const LandingBookSellingPage = () => {
  return (
    <MainLayout
      showFooterRegister={false}
      renderCustomHeader={() => <HeaderSchedulePage hideMobileBottomNav />}
      noHeader
      hideSideCTA
    >
      <MySEO
        title="Thông não IELTS Reading cùng Linearthinking"
        description="Khác với SCAN, SKIM và ĐỌC DỊCH, cuốn sách này mang đến cho các bạn 1 phương pháp học Reading tư duy và hiệu quả hơn - Linearthinking. Linear thiên về cách học tư duy, thông minh, chứ không phải học mẹo, học kỹ thuật. Mua sách trên Tiki để được ưu đãi 10% và freeship toàn quốc."
        featureImg={{ handle: 's04Qmq3ISZqDh2LJjbLR' }}
      />

      <Main className="landing-book-selling-page">
        {/*<BookSellingHeroBanner />*/}
        <ContentSection />
        <BookProductFooter />
      </Main>
    </MainLayout>
  );
};

export default LandingBookSellingPage;
